








































































































































































import {Component, Prop, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Indicador} from '@/plugins/api';
import EditarBanco from './EditarBanco.vue';

@Component({
  components: {
    EditarBanco,
  },
})
export default class CardIndicador extends Vue {
  @PropSync('codigo', {type: Number}) public readonly indicadorId!: number;
  @Prop() public readonly data!: IIndicador;
  public indicador = {} as IIndicador;

  public loadingIndicador = true;
  public loadingBanco = true;

  public motivoRejeicao = '';
  public processing = false;

  public galleryVisible = false;

  public motivos = [
    'A conta cadastrada é pessoa juridica, favor cadastrar conta pessoa física',
    'Cadastrar dados bancários e anexar comprovante de dados bancários',
    'Conferir dados bancários, informações incompletas/divergentes',
  ];

  get banco(): IDadosBancarios {
    return {
      ...this.indicador.dados_bancarios,
      bancos: this.indicador.bancos,
    };
  }

  get bancoNome(): string {
    return this.indicador?.bancos?.nome ?? '';
  }

  mounted(): void {
    if (Object.keys(this.data ?? []).length === 0) {
      this.updateInfo();
    } else {
      this.updateData();
    }
  }

  @Watch('data')
  updateData(): void {
    this.loadingIndicador = false;
    this.loadingBanco = false;
    this.indicador = this.data;
  }

  @Watch('indicadorId')
  updateInfo(): void {
    this.loadingIndicador = true;
    this.loadingBanco = true;
    if ((this.indicador.id ?? 0) !== 0) {
      Indicador.getByID(this.indicador.id)
          .then((response: GetIndicadorResponse) => {
            this.indicador = response.data;
          }).finally(() => {
        this.loadingIndicador = false;
        this.loadingBanco = false;
      });
    }
  }

  updateBanco(): void {
    this.loadingBanco = true;
    Indicador.getBanco(this.indicador.id)
        .then((response: GetDadosBancariosResponse) => {
          this.indicador['dados_bancarios'] = response.data as IDadosBancarios;
        })
        .finally(() => {
          this.loadingBanco = false;
        });
  }

  get imagePerfil(): string {
    if (this.indicador.perfil !== undefined && this.indicador.perfil !== '') {
      return '//dashboard.parceirosproteauto.com.br' + this.indicador.perfil;
    }
    return '//dashboard.parceirosproteauto.com.br/painel/images/perfil/sem-imagem.svg';
  }

  get bancoTipo(): string {
    let tipo = this.banco?.tipo ?? '';
    if (tipo === 'cc') {
      tipo = 'Corrente';
    } else if (tipo === 'cp') {
      tipo = 'Poupança';
    }
    return tipo;
  }

  get pixTipo(): string {
    let tipo = this.banco?.pix_tipo ?? '';
    if (tipo === 'nao_usar') {
      tipo = 'Não usar';
    } else if (tipo === 'chave_aleatoria') {
      tipo = 'Chave aleatória';
    } else if (tipo === 'cpf_cnpj') {
      tipo = 'CPF/CNPJ';
    } else if (tipo === 'telefone') {
      tipo = 'Telefone';
    } else if (tipo === 'email') {
      tipo = 'Email';
    }
    return tipo;
  }

  get bancoSituacao(): { label: string; icon: string; color: string } {
    const tipo = this.banco?.situacao ?? '';
    switch (tipo) {
      case 'pendente':
        return {
          label: 'Pendente',
          icon: 'mdi-credit-card-clock',
          color: 'text-warning',
        };
      case 'verificado':
        return {
          label: 'Validado',
          icon: 'mdi-credit-card ',
          color: 'text-success',
        };
      case 'rejeitado':
        return {
          label: 'Rejeitado',
          icon: 'mdi-credit-card-off',
          color: 'text-danger',
        };
      default:
        return {
          label: 'Não Informado',
          icon: 'mdi-credit-card-off',
          color: 'text-secundary',
        };
    }
  }

  get bancoSituacaoLabel(): string {
    return this.bancoSituacao.label;
  }

  get bancoSituacaoIcon(): string {
    return this.bancoSituacao.icon;
  }

  get bancoSituacaoColor(): string {
    return this.bancoSituacao.color;
  }

  get bancoDisabled(): boolean {
    return this.banco?.id === undefined;
  }

  get cartoes(): string[] {
    const foto = this.banco.foto_cartao;
    if (foto != undefined && foto !== '') {
      return ['https://dashboard.parceirosproteauto.com.br' + foto];
    }
    return [];
  }

  get cartoesDisabled(): boolean {
    return this.cartoes.length === 0;
  }

  setBancoSituacao(situacao: string): void {
    this.loadingBanco = true;
    const data: PatchIndicadorRequest = {
      id: this.banco.id,
      situacao: situacao,
      motivo: this.motivoRejeicao,
    };
    Indicador.patchBanco(this.indicador.id, data).then(() => {
      this.updateBanco();
    });
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }
}
